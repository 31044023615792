import { useGetRequest } from 'api/useGetRequest';
import {
  AppointmentControllerService,
  CalenderEventControllerService,
  OutlierControllerService,
} from 'generated';

interface UseGetOutlierStatisticsProps {
  callback?: () => void;
  subscribedMembers?: boolean;
  coachId?: string;
}

const useGetAppointmentsStatistics = ({ callback, coachId }: UseGetOutlierStatisticsProps = {}) => {
  const { data, loading, refetch } = useGetRequest({
    service: CalenderEventControllerService.calenderEventControllerCalendarEventStatistics,
    tag: 'calenderEventControllerCalendarEventStatistics',
    payload: {
      coachId,
    },
    onSuccess: () => {
      callback?.();
    },
  });

  return {
    calendarEventsStats: data,
    calendarEventsStatsLoading: loading,
    refetch,
  };
};

export default useGetAppointmentsStatistics;
