const WithCounter = ({ statisticNumber, isActive, hoverIcon, children }) => {
  return (
    <div className="flex justify-between">
      {children}
      <div>
        <div
          style={{
            color: isActive ? '#FFF' : hoverIcon ? '#FD6E5E' : '#FD6E5E',
            transition: '.2s ease-in-out',
            border: `1px solid ${isActive ? '#FFF' : hoverIcon ? '#FD6E5E' : '#FD6E5E'}`,
            width: statisticNumber > 999 ? 'fit-content' : null,
            padding: `0 ${statisticNumber > 999 ? '5px' : '0'}`,
          }}
          className={`rounded-full w-6 h-6 flex justify-center font-bold items-center ${
            statisticNumber > 99 ? 'text-[10px] leading-[10px]' : 'text-xs'
          }`}
        >
          {statisticNumber > 999 ? '999+' : statisticNumber}
        </div>
      </div>
    </div>
  );
};

export default WithCounter;
