import { useState } from 'react';
import WithCounter from './WithCounter';

interface IMenuItemProps {
  title: string;
  icon: string;
  display: string;
  isActive?: boolean;
  onClick: () => void;
  outlierStats?: {
    in_review?: number;
    resolved?: number;
    pending?: number;
  };
  referralStats?: {
    totalReferrals?: number;
    totalOutboundReferrals?: number;
    totalPendingReferrals?: number;
    totalAcceptedReferrals?: number;
  };
  appointmentsStats?: {
    currentMonthUpcomingCalendarEvents?: number;
  };
}

const MenuItem = ({
  title,
  icon,
  display,
  isActive,
  onClick,
  outlierStats,
  referralStats,
  appointmentsStats,
}: IMenuItemProps) => {
  const [hoverIcon, setHoverIcon] = useState(false);

  const renderItemWithCounter = (title: string) => {
    switch (true) {
      case title === 'Outliers' && outlierStats?.pending > 0:
        return (
          <WithCounter
            statisticNumber={outlierStats?.pending}
            isActive={isActive}
            hoverIcon={hoverIcon}
          >
            {title}
          </WithCounter>
        );
      case title === 'Referrals' && referralStats?.totalPendingReferrals > 0:
        return (
          <WithCounter
            statisticNumber={referralStats?.totalPendingReferrals}
            isActive={isActive}
            hoverIcon={hoverIcon}
          >
            {title}
          </WithCounter>
        );
      case title === 'Appointments' && appointmentsStats?.currentMonthUpcomingCalendarEvents > 0:
        return (
          <WithCounter
            statisticNumber={appointmentsStats?.currentMonthUpcomingCalendarEvents}
            isActive={isActive}
            hoverIcon={hoverIcon}
          >
            {title}
          </WithCounter>
        );
      default:
        return title;
    }
  };

  return (
    <div
      onClick={onClick}
      className={`${isActive && 'bg-orange'} py-4 flex items-center ${
        display === 'none' ? 'justify-center' : ''
      } px-3 gap-2 cursor-pointer ${
        !isActive && 'hover:bg-hovOrange'
      } rounded-lg h-[55px] w-[90%] mb-1 group`}
      style={{
        transition: '.2s ease-in-out',
        borderColor: isActive ? '#f26522' : undefined,
      }}
      onMouseEnter={() => setHoverIcon(true)}
      onMouseLeave={() => setHoverIcon(false)}
    >
      <div
        className="flex justify-center items-center"
        style={{
          color: isActive ? '#FFF' : hoverIcon ? '#FD6E5E' : '#666',
          transition: '.2s ease-in-out',
        }}
      >
        <i className={`${icon} text-[20px]`}></i>
      </div>

      <div
        className={`flex flex-row justify-between grow text-md ${
          !isActive && 'group-hover:text-darkOrange'
        } whitespace-nowrap ${isActive ? 'text-white' : 'text-gray-800'}`}
        style={{ display, transition: '.2s ease-in-out' }}
      >
        {renderItemWithCounter(title)}
      </div>
    </div>
  );
};

export default MenuItem;
