export const mealOptions = [
  { label: 'Breakfast', value: 'breakfast' },
  { label: 'Dinner', value: 'dinner' },
  { label: 'Fasting', value: 'fasting' },
  { label: 'Lunch', value: 'lunch' },
  { label: 'Random', value: 'random' },
  { label: 'Snacks', value: 'snack' },
];

export const mealOptionsValues = mealOptions.map(({ value }) => value);

export const glucoseUnits = [
  {
    label: 'mg/dL',
    value: 'mg/dL',
  },
  {
    label: 'mmol/L',
    value: 'mmol/L',
  },
];

export const dosageUnitOptions = [
  {
    label: 'mg',
    value: 'mg',
  },
  {
    label: 'g',
    value: 'g',
  },
  {
    label: 'ml',
    value: 'ml',
  },
  {
    label: 'iu',
    value: 'iu',
  },
];

export const sourceOptions = [
  {
    value: 'doctor',
    label: 'Doctor',
  },
  {
    value: 'nurse',
    label: 'Nurse',
  },
  {
    value: 'self-prescribed',
    label: 'Self Prescribed',
  },
  {
    value: 'pharmacy',
    label: 'Pharmacy',
  },
  {
    value: 'family-and-friends',
    label: 'Family & Friends',
  },
  {
    value: 'chemist',
    label: 'Chemist',
  },
  {
    value: 'primary-healthcare',
    label: 'Primary Healthcare',
  },
];

export const sourceOptionsPrescriptions = [
  {
    value: 'doctor',
    label: 'Doctor',
  },
  {
    value: 'nurse',
    label: 'Nurse',
  },
  {
    value: 'self-prescribed',
    label: 'Self Prescribed',
  },
  {
    value: 'pharmacy',
    label: 'Pharmacy',
  },
  {
    value: 'family-and-friends',
    label: 'Family & Friends',
  },
  {
    value: 'chemist',
    label: 'Chemist',
  },
  {
    value: 'primary-healthcare',
    label: 'Primary Healthcare',
  },
];

export const glucoseUnitsValues = glucoseUnits.map(({ value }) => value);

export const weightUnits = [
  {
    label: 'KG',
    value: 'KG',
  },
  {
    label: 'LBS',
    value: 'LBS',
  },
] as const;
export const weightUnitsValues = weightUnits.map(({ value }) => value);

export const waistUnits = [
  {
    label: 'IN',
    value: 'IN',
  },
  {
    label: 'CM',
    value: 'CM',
  },
] as const;

export const waistUnitsValues = waistUnits.map(({ value }) => value);

export const temperatureUnits = [
  {
    label: 'celsius',
    value: 'celsius',
  },
  {
    label: 'fahrenheit',
    value: 'fahrenheit',
  },
] as const;

export const temperatureUnitsValues = temperatureUnits.map(({ value }) => value);

export const heightUnits = [
  {
    label: 'CM',
    value: 'CM',
  },
  {
    label: 'FT/IN',
    value: 'FT/IN',
  },
  {
    label: 'METRE(S)',
    value: 'M',
  },
] as const;

export const heightUnitsValues = heightUnits.map(({ value }) => value);

export const glitchesTypeValues = [
  { label: 'Network', value: 'network' },
  { label: 'Hardware', value: 'hardware' },
  { label: 'Software', value: 'software' },
  { label: 'Other', value: 'other' },
];
export const consultOutcomeValues = [
  { label: 'Referral', value: 'referral' },
  { label: 'Follow up', value: 'follow-up' },
  { label: 'Prescription', value: 'prescription' },
  // { label: 'Diagnosis', value: 'diagnosis' },
  // { label: 'Action plan', value: 'action-plan' },
];

export const diagnosisValues = [
  { label: 'Malaria', value: 'malaria' },
  { label: 'Hypertension', value: 'hypertension' },
  { label: 'Fever', value: 'fever' },
  { label: 'Diabetes', value: 'diabetes' },
  { label: 'Other', value: 'other' },
];

export const linkedMetricsOptions = [
  {
    label: 'Weight',
    value: 'weight',
  },
  {
    label: 'Blood Pressure',
    value: 'blood-pressure',
  },
  {
    label: 'Blood Glucose',
    value: 'blood-glucose',
  },
  {
    label: 'Cholesterol',
    value: 'cholesterol',
  },

  {
    label: 'Waist',
    value: 'waist',
  },
];

export const actionPlanFrequencyOptions = [
  {
    label: 'Daily',
    value: 'daily',
  },
  {
    label: 'Weekly',
    value: 'weekly',
  },
  {
    label: 'Bi-weekly',
    value: 'bi-weekly',
  },
  {
    label: 'Custom',
    value: 'custom',
  },
];
export const daysOfWeekOptions = [
  {
    label: 'Sunday',
    value: 'sunday',
  },
  {
    label: 'Monday',
    value: 'monday',
  },
  {
    label: 'Tuesday',
    value: 'tuesday',
  },
  {
    label: 'Wednesday',
    value: 'wednesday',
  },
  {
    label: 'Thursday',
    value: 'thursday',
  },
  {
    label: 'Friday',
    value: 'friday',
  },
  {
    label: 'Saturday',
    value: 'saturday',
  },
];

export const myReferralsSortOptions = [
  {
    label: 'Date',
    value: 'createdAtSort',
  },
  {
    label: 'Member name',
    value: 'userFullNameSort',
  },
  {
    label: 'Referrer',
    value: 'senderCoachNameSort',
  },
  {
    label: 'Reason',
    value: 'referralReasonSort',
  },
  {
    label: 'Specific',
    value: 'reasonTypeSort',
  },

  {
    label: 'Status',
    value: 'acceptanceStatusSort',
  },
];

export const referredByMeSortOptions = myReferralsSortOptions.filter(
  (option) => option.value !== 'senderCoachNameSort',
);

export const myExternalReferralsSortOptions = [
  {
    label: 'Date',
    value: 'createdAtSort',
  },
  {
    label: 'Member name',
    value: 'userFullNameSort',
  },
  {
    label: 'Reason',
    value: 'referralReasonSort',
  },

  {
    label: 'Specific',
    value: 'reasonTypeSort',
  },

  {
    label: 'Status',
    value: 'referralStatusSort',
  },
];
